import 'simplebar/dist/simplebar.min.css';

import moment, { Moment } from 'moment-timezone';
import SimpleBar from 'simplebar-react';

import { WhatsappOutlined } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';

import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { DatewiseSlots } from '../../types';
import { Button } from '../buttons';

type TimeDatePickerProps = {
  datewiseSlots: DatewiseSlots;
  selectedDate: string | null;
  timezone: string;
  loading: boolean;
  availableDaysInAdvance: number;
  handleWhatsappClick?: () => void;
  onSelectDate: (date: string) => void;
};

type DateCardProps = {
  currentDate: Moment;
  currentDaySlots: string[];
  selectedDate: string | null;
  timezone: string;
  loading: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
  onSelectDate: (date: string) => void;
};

const DateCard = ({
  currentDate,
  currentDaySlots,
  selectedDate,
  loading,
  cardRef,
  onSelectDate
}: DateCardProps) => {
  const slotAvailable = !!currentDaySlots && currentDaySlots.length > 0;
  const isToday = currentDate.isSame(moment(), 'day');

  const isSelected = selectedDate === currentDate.format('DD-MM-YYYY');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      ref={isSelected ? cardRef : null}
    >
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="44px"
          height="44px"
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '52px',
            backgroundColor: isSelected
              ? '#1482DE'
              : slotAvailable
              ? '#FFFFFF'
              : '#F3F5F6',
            height: '52px',
            borderRadius: '10px',
            border: isSelected
              ? '1px solid #1482DE'
              : slotAvailable
              ? '1px solid #E3E7ED'
              : 'none',
            boxShadow: slotAvailable
              ? '0px 5px 7px 0px #1F2A340A, 0px 0.5px 1px 0px #1F2A3414'
              : 'none',
            cursor: slotAvailable ? 'pointer' : 'not-allowed'
          }}
          onClick={() =>
            slotAvailable && !isSelected
              ? onSelectDate(currentDate.format('DD-MM-YYYY'))
              : null
          }
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '10px',
              fontWeight: isSelected ? 500 : 400,
              color: isSelected ? 'white' : slotAvailable ? '#A9ADB7' : '#969AA5'
            }}
          >
            {isToday ? 'Today' : moment(currentDate).format('ddd')}
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              color: isSelected ? 'white' : slotAvailable ? '#1F2127' : '#C3C6D0'
            }}
          >
            {moment(currentDate).format('D')}
          </Typography>
        </Box>
      )}
      {slotAvailable && !loading ? (
        <Typography
          sx={{
            fontSize: '11px',
            color: '#969AA5',
            paddingTop: '4px',
            paddingBottom: '2px'
          }}
        >
          {currentDaySlots.length} slots
        </Typography>
      ) : (
        <Box sx={{ pb: '9px' }} />
      )}
    </Box>
  );
};

const TimelyDatePicker = ({
  datewiseSlots,
  selectedDate,
  timezone,
  loading,
  availableDaysInAdvance,
  handleWhatsappClick,
  onSelectDate
}: TimeDatePickerProps) => {
  const noSlots = Object.keys(datewiseSlots).length === 0;
  const daysInAdvance = noSlots ? 7 : Math.max(availableDaysInAdvance, 7);
  const dateCardRef = useRef<HTMLDivElement>(null);
  const showScrollbar = Object.keys(datewiseSlots).length > 7;

  useEffect(() => {
    // Scroll to the selected date card
    if (selectedDate && dateCardRef.current) {
      dateCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }, [selectedDate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography sx={{ fontSize: '12px', color: '#7B838C' }}>
        Availability
      </Typography>
      <SimpleBar
        style={{ width: '100%' }}
        autoHide={isMobile ? true : showScrollbar ? false : true}
      >
        <Box
          display="grid"
          gridTemplateColumns={`repeat(${daysInAdvance}, 1fr)`}
          gap="10px"
          sx={{ pb: 1 }}
        >
          {daysInAdvance > 0 &&
            new Array(daysInAdvance).fill(null).map((_, index) => {
              const currentDate = moment().tz(timezone).add(index, 'days');

              return (
                <DateCard
                  key={index}
                  cardRef={dateCardRef}
                  currentDate={currentDate}
                  currentDaySlots={datewiseSlots[currentDate.format('DD-MM-YYYY')]}
                  selectedDate={selectedDate}
                  timezone={timezone}
                  loading={loading}
                  onSelectDate={onSelectDate}
                />
              );
            })}
        </Box>
      </SimpleBar>
      {noSlots ? (
        <Box
          sx={{
            width: '100%',
            px: 2,
            py: 1,
            gap: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F1F7F3',
            borderRadius: '8px'
          }}
        >
          <Typography
            sx={{ fontSize: '13px', borderRight: '1px solid #C5E1CB', pr: 1 }}
          >
            Sorry! No slots available at this time. Not a fan of waiting?
          </Typography>
          <Button
            sx={{
              fontSize: '12px',
              color: 'white',
              backgroundColor: 'rgb(84, 199, 150)',
              '&:hover': {
                backgroundColor: 'rgb(67, 159, 120)'
              },
              py: 1,
              px: 2,
              flexShrink: 0
            }}
            startIcon={
              <WhatsappOutlined style={{ width: '18px', color: 'white' }} />
            }
            onClick={handleWhatsappClick}
          >
            WhatsApp us
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export { TimelyDatePicker };
